import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { default as Layout } from "../gatsby-theme-hypersite/layout";
import { Box, Typography, Grid, withStyles } from "@material-ui/core";
import {
	Chart,
	DataContext,
	DownloadData,
	JumpLinks,
	JumpTarget,
	JurisdictionHero,
	LearnMore,
	Map,
	MoreStats,
	PolicySummary,
	Stats,
	Toggle,
	getLevel
} from "../components";
import { formatDate } from "../utils";
import { hasPolicySummary } from "../components/blocks/policy-summary/schema";

const CountyLayout = ({ classes, children, hero, data, ...props }) => {
	const national = data.national;
	const county = data.county;
	const state = data.state;
	const monthly = data.monthly.nodes;
	const weekly = data.weekly.nodes;
	const faq = data.faq;
	const methodology = data.methodology;
	const stateMonthly = data.stateMonthly.nodes;
	const stateWeekly = data.stateWeekly.nodes;
	const { root, container, content, imageWrapper, image } = classes;

	const sections = [
		{ slug: "about", name: `About this ${getLevel(data.county.fips)}` },
		{ slug: "evictions", name: "Eviction filings and defaults" },
		{ slug: "policy", name: "Policy summary" },
		{ slug: "data", name: "Download the data" },
		{ slug: "learn", name: "FAQs & Methodology" }
	].filter(s => (!hasPolicySummary(state) ? s.slug !== "policy" : true));
	const sectionIds = sections.map(s => s.slug);

	return (
		<Layout
			{...props}
			sections={sections}
			HeroComponent={
				<JurisdictionHero
					classes={{ root, container, content, imageWrapper, image }}
					image={
						<Map
							level="state"
							countyFips={county.fips}
							multiCountyFips={county.fips_if_multi_county}
							stateName={county.state_name}
							stateFips={county.state_fips}
							stateShort={county.state_short}
							bbox={state.bbox}
							isInHero={true}
						/>
					}
					color="text.primary"
					alt=""
				>
					<Typography
						component="div"
						variant="subtitle1"
					>
						{hero.title}
					</Typography>
					<Typography
						component="h1"
						variant="h2"
						className={classes.title}
						gutterBottom
					>
						{county.name}
					</Typography>
					<Stats
						data={county}
						//national={national}
					/>
				</JurisdictionHero>
			}
		>
			<JumpTarget id="about" />

			<MoreStats
				level={getLevel(data.county.fips)}
				data={county}
				national={national}
			/>

			<JumpTarget id="evictions" />

			<DataContext
				name={`${county.name}, ${county.state_name}`}
				// stateName={county.state_name}
				moratoria={state.moratorium_dates}
				context={county.text_context}
			/>

			<Chart
				level="county"
				name={`${county.name}, ${county.state_name}`}
				data={{
					monthly,
					weekly
				}}
				avgData={{
					monthly: stateMonthly,
					weekly: stateWeekly
				}}
			/>

			<JumpTarget id="policy" />

			<PolicySummary data={county} />

			<JumpTarget id="data" />

			<DownloadData data={county} />

			<JumpTarget id="learn" />

			<LearnMore faq={faq} methodology={methodology} />
		</Layout>
	);
};

CountyLayout.defaultProps = {
	hero: {
		title: "Trends in eviction filings:"
		// lede: "The Legal Services Corporation (LSC) Eviction Tracker provides access to multi-year trend data on eviction filings for 1,250 counties and municipalities in 30 states and territories across the United States.",
		// image: "/images/hero-county-adams-pa.png",
	}
};

CountyLayout.propTypes = {};

export default withStyles(theme => ({
	root: {
		// paddingTop: 0
	},
	heading: {
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(24)
	},
	paragraph: {
		maxWidth: 438,
		[theme.breakpoints.up("md")]: {
			marginLeft: theme.spacing(0)
		},
		[theme.breakpoints.up("lg")]: {
			marginLeft: theme.spacing(0)
		}
	}
}))(CountyLayout);

export const query = graphql`
	query ($fips: Int, $state_fips: Int) {
		national {
			filings_count
			filings_latest
			filings_latest_date
			rent_burdened
			poverty_rate
		}
		county: counties(fips: { eq: $fips }) {
			fips
			fips_if_multi_county
			name
			state_fips
			state_name
			state_short
			filings_rate
			filings_count
			filings_latest
			filings_latest_date
			represent_tenants
			represent_landlords
			disposed_case_count
			cases_default
			cases_judgment
			rent_burdened
			poverty_rate
			text_context
			text_about
			withhold_rent
			notice_req_nonpay
			notice_min_nonpay
			notice_req_other
			notice_min_other
			edp_require
			notice_ls
			filing_min_nonpay
			first_court
			filing_fee
			just_cause
			summons_respond
			summons_min
			summons_ls
			pay_after_filing
			appeal_allowed
			appeal_min
			appeal_bond
			writ_issue_min
			writ_exec_min
			pay_after_judg
			seal_cases
			reg_disclosure
			withhold_rent_info
			notice_req_nonpay_info
			notice_min_nonpay_info
			notice_req_other_info
			notice_min_other_info
			edp_require_info
			notice_ls_info
			filing_min_nonpay_info
			first_court_info
			filing_fee_info
			just_cause_info
			summons_respond_info
			summons_min_info
			summons_ls_info
			pay_after_filing_info
			appeal_allowed_info
			appeal_min_info
			appeal_bond_info
			writ_issue_min_info
			writ_exec_min_info
			pay_after_judg_info
			seal_cases_info
			reg_disclosure_info
		}
		state: states(fips: { eq: $state_fips }) {
			moratorium_dates
			bbox
		}
		monthly: allMonthly(filter: { fips: { eq: $fips } }) {
			nodes {
				fips
				name
				date
				filings_count
				percent_of_historical_average
				defaults_count
				defaults_rate
			}
		}
		weekly: allWeekly(filter: { fips: { eq: $fips } }) {
			nodes {
				fips
				name
				date
				filings_count
			}
		}
		stateMonthly: allMonthly(filter: { fips: { eq: $state_fips } }) {
			nodes {
				fips
				name
				date
				filings_count
				percent_of_historical_average
				judgments_count
				defaults_count
				defaults_rate
			}
		}
		stateWeekly: allWeekly(filter: { fips: { eq: $state_fips } }) {
			nodes {
				fips
				name
				date
				filings_count
			}
		}
		faq: mdx(slug: { eq: "faq" }) {
			frontmatter {
				thumb
				path
			}
		}
		methodology: mdx(slug: { eq: "methodology" }) {
			frontmatter {
				thumb
				path
			}
		}
	}
`;
